<template>
  <div class="container mypage withTop">
    <section class="contents Rgt daily" ref="Rgt">
      <article class="box">
        <p class="bold">
          영농작업
          <span>*</span>
        </p>
        <div class="categoryWrap">
          <input type="checkbox" id="category1" @change="selectCategory" :checked="category.includes('category1')" />
          <label for="category1">농약</label>
          <input type="checkbox" id="category2" @change="selectCategory" :checked="category.includes('category2')" />
          <label for="category2">관수</label>
          <input type="checkbox" id="category3" @change="selectCategory" :checked="category.includes('category3')" />
          <label for="category3">해충기록</label>
          <input type="checkbox" id="category4" @change="selectCategory" :checked="category.includes('category4')" />
          <label for="category4">장비정비</label>
          <input type="checkbox" id="category5" @change="selectCategory" :checked="category.includes('category5')" />
          <label for="category5">수확</label>
          <input type="checkbox" id="category6" @change="selectCategory" :checked="category.includes('category6')" />
          <label for="category6">출고</label>
          <input type="checkbox" id="category7" @change="selectCategory" :checked="category.includes('category7')" />
          <label for="category7">기타</label>
        </div>
        <p class="bold">
          작물
          <span>*</span>
        </p>
        <el-select v-model="crop">
          <el-option value="딸기" label="딸기"></el-option>
          <el-option value="토마토" label="토마토"></el-option>
          <el-option value="사과" label="사과"></el-option>
          <el-option value="고추" label="고추"></el-option>
          <el-option value="포도" label="포도"></el-option>
          <el-option value="레드향" label="레드향"></el-option>
          <el-option value="상추" label="상추"></el-option>
        </el-select>
        <p class="bold">
          작업일
          <span>*</span>
        </p>
        <Datepicker v-model="date" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
      </article>

      <div class="nodata" v-if="category.length == 0">
        <p>영농작업 종류를 선택해 주세요.</p>
      </div>

      <article class="box" v-if="category.includes('category1')">
        <p class="bold">농약</p>
        <p>처리날짜</p>
        <Datepicker v-model="pesticide_1" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>

        <p>농약제품</p>
        <input type="text" @click="handleFocus" v-model="pesticide_2" />
        <p>대상병해충</p>
        <input type="text" @click="handleFocus" v-model="pesticide_3" />
        <p>농약제품 구입량</p>
        <input type="text" @click="handleFocus" v-model="pesticide_4" />
        <p>농약제품 사용량</p>
        <input type="text" @click="handleFocus" v-model="pesticide_5" />
        <p>농약처리방법</p>
        <el-checkbox-group v-model="pesticide_6">
          <el-checkbox label="관수"></el-checkbox>
          <el-checkbox label="기계"></el-checkbox>
        </el-checkbox-group>
        <p>농약처리장비명</p>
        <el-checkbox-group v-model="pesticide_7">
          <el-checkbox label="SS기"></el-checkbox>
          <el-checkbox label="동력분무기"></el-checkbox>
        </el-checkbox-group>
      </article>

      <article class="box" v-if="category.includes('category2')">
        <p class="bold">관수</p>
        <p>수원</p>
        <input type="text" @click="handleFocus" v-model="irrn_1" />
        <p>수원번호</p>
        <input type="text" @click="handleFocus" v-model="irrn_2" />
        <p>관수시간</p>
        <el-time-select
          :picker-options="{
            start: '08:00',
            step: '00:30',
            end: '24:00',
          }"
          placeholder="선택"
          v-model="irrn_3"
        >
        </el-time-select>
        <p>관수량</p>
        <input type="text" @click="handleFocus" v-model="irrn_4" />
      </article>

      <article class="box" v-if="category.includes('category3')">
        <p class="bold">해충기록</p>
        <p>관찰날짜</p>
        <Datepicker v-model="dbyhs_1" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
        <p>조사방법</p>
        <input type="text" @click="handleFocus" v-model="dbyhs_2" />
      </article>

      <article class="box" v-if="category.includes('category4')">
        <p class="bold">장비정비</p>
        <p>장비종류</p>
        <input type="text" @click="handleFocus" v-model="equipment_1" />
        <p>관리사유</p>
        <input type="text" @click="handleFocus" v-model="equipment_2" />
      </article>

      <article class="box" v-if="category.includes('category5')">
        <p class="bold">수확</p>
        <p>생산량</p>
        <input type="text" @click="handleFocus" v-model="hvst_1" />
        <p>생산날짜</p>
        <Datepicker v-model="hvst_2" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
      </article>

      <article class="box" v-if="category.includes('category6')">
        <p class="bold">출고</p>
        <p>출하량</p>
        <input type="text" @click="handleFocus" v-model="release_1" />
        <p>출하날짜</p>
        <Datepicker v-model="release_2" :language="ko" :format="customFormatter" placeholder="선택" @focusin.native="handleFocus"></Datepicker>
      </article>

      <article class="box" v-if="category.includes('category7')">
        <p class="bold">기타</p>
        <p>메모</p>
        <textarea rows="5" v-model="etc_1" @click="handleFocus"></textarea>
      </article>
      <article class="box" v-if="category != ''">
        <p>사진추가</p>
        <div class="imgBox">
          <ul>
            <li>
              <label for="imgUpload">
                <span class="material-icons-round"> camera_alt </span>
                <p>사진추가</p>
              </label>
              <input type="file" accept="image/*" ref="images" id="imgUpload" @change="sendFile()" />
            </li>
          </ul>
          <ul v-if="images && images.length > 0">
            <li v-for="(image, i) in images" :key="i">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image)">
                <img src="@/assets/images/ic_delete_wh.svg" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </article>

      <button class="point full" @click="submit">
        {{ editMode == true ? "수정하기" : "등록하기" }}
      </button>
    </section>
  </div>
</template>
<script>
import { fetchDiaryDetails, createDiary, updateDiary } from "@/api/diary";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import { mapState } from "vuex";
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  data() {
    return {
      ko: ko,
      moment: moment,
      editMode: false,
      category: [],
      farmer: "",
      crop: "",
      pesticide_1: "", //농약
      pesticide_2: "",
      pesticide_3: "",
      pesticide_4: "",
      pesticide_5: "",
      pesticide_6: [],
      pesticide_7: [],
      irrn_1: "", //관수
      irrn_2: "",
      irrn_3: "",
      irrn_4: "",
      dbyhs_1: "", //해충
      dbyhs_2: "",
      equipment_1: "", // 장비
      equipment_2: "",
      hvst_1: "", //수확
      hvst_2: "",
      release_1: "", // 출고
      release_2: "",
      etc_1: "", //기타
      date: "",
      images: [],
      file: "",
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "영농일지등록");
  },
  mounted() {
    if (this.$route.query.id) {
      this.diaryId = this.$route.query.id;
      this.editMode = true;
      this.getDiaryDetail();
    }
    if (this.$route.query.farmer) {
      this.farmer = this.$route.query.farmer;
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getDiaryDetail() {
      fetchDiaryDetails(this.diaryId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.farmer = res.data.data.userId;
          this.crop = res.data.data.crop;
          this.pesticide_1 = res.data.data.pesticide_1; //농약
          this.pesticide_2 = res.data.data.pesticide_2;
          this.pesticide_3 = res.data.data.pesticide_3;
          this.pesticide_4 = res.data.data.pesticide_4;
          this.pesticide_5 = res.data.data.pesticide_5;
          this.pesticide_6 = res.data.data.pesticide_6;
          this.pesticide_7 = res.data.data.pesticide_7;
          this.irrn_1 = res.data.data.irrn_1; //관수
          this.irrn_2 = res.data.data.irrn_2;
          this.irrn_3 = res.data.data.irrn_3;
          this.irrn_4 = res.data.data.irrn_4;
          this.dbyhs_1 = res.data.data.dbyhs_1; //해충
          this.dbyhs_2 = res.data.data.dbyhs_2;
          this.equipment_1 = res.data.data.equipment_1; // 장비
          this.equipment_2 = res.data.data.equipment_2;
          this.hvst_1 = res.data.data.hvst_1; //수확
          this.hvst_2 = res.data.data.hvst_2;
          this.release_1 = res.data.data.release_1; // 출고
          this.release_2 = res.data.data.release_2;
          this.etc_1 = res.data.data.etc_1; //기타
          this.images = res.data.data.images;
          this.date = res.data.data.date;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    submit() {
      if (this.category.length == 0) {
        this.openAlert("영농작업을 선택해 주세요.");
        return;
      } else if (this.crop == "") {
        this.openAlert("작물을 선택해 주세요.");
        return;
      } else if (this.date == "") {
        this.openAlert("작업일을 선택해 주세요.");
        return;
      }

      let data = {
        diaryId: this.diaryId,
        category: this.category,
        farmer: this.farmer,
        crop: this.crop,
        pesticide_1: this.pesticide_1, //농약
        pesticide_2: this.pesticide_2,
        pesticide_3: this.pesticide_3,
        pesticide_4: this.pesticide_4,
        pesticide_5: this.pesticide_5,
        pesticide_6: this.pesticide_6,
        pesticide_7: this.pesticide_7,
        irrn_1: this.irrn_1, //관수
        irrn_2: this.irrn_2,
        irrn_3: this.irrn_3,
        irrn_4: this.irrn_4,
        dbyhs_1: this.dbyhs_1, //해충
        dbyhs_2: this.dbyhs_2,
        equipment_1: this.equipment_1, // 장비
        equipment_2: this.equipment_2,
        hvst_1: this.hvst_1, //수확
        hvst_2: this.hvst_2,
        release_1: this.release_1, // 출고
        release_2: this.release_2,
        etc_1: this.etc_1, //기타
        images: this.images,
        date: this.date,
      };
      if (this.editMode == true) {
        updateDiary(data).then((res) => {
          if (res.data.status == 200) {
            this.$router.replace({ name: "daily", query: { id: this.farmer } });
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createDiary(data).then((res) => {
          if (res.data.status == 200) {
            this.$router.replace({ name: "daily", query: { id: this.farmer } });
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
    },
    selectCategory(e) {
      if (e.target.checked == true) {
        this.category.push(e.srcElement.id);
      } else {
        let arr = this.category.filter((item) => item !== e.srcElement.id);
        this.category = arr;
      }
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined || this.file == "") {
        return;
      }

      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then((res) => {
        if (res.status == 200) {
          this.file = "";
          document.getElementById("imgUpload").value = "";
          this.images.push(res.data.url);
        } else {
          this.openAlert("서버오류 입니다. 다시 파일을 업로드 해주세요.");
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          if (this.editMode) {
            deletePostFile(this.diaryId, url).then((res) => {
              if (res.data.status == 200) {
                this.images.splice(this.images.indexOf(url), 1);
              } else {
                this.openAlert(res.data.message);
              }
            });
          } else {
            deleteFile(url).then((res) => {
              if (res.data.status == 200) {
                this.images.splice(this.images.indexOf(url), 1);
                document.getElementById("imgUpload").value = "";
              } else {
                this.openAlert(res.data.message);
              }
            });
          }
        })
        .catch(() => {});
    },
    handleFocus() {
      this.$refs.Rgt.style.paddingBottom = "200px";
      document.activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
